import Vue from 'vue'
import Vuex from 'vuex'
import axios from 'axios'
import router from '../router/index'

Vue.use(Vuex)

let retryCount = 10;

export default new Vuex.Store({
    state: {
        version:"v2.2.21",
        inDevelopment: true,
        snackOptions: {
            display: false,
            iconColor: "",
            icon: "",
            text: ""
        },
        privateLogin:false,
        accessMap:{},
        loadingScreenOptions: {
            display: false,
        },
        userProfile:{
            name:null,
            jwt:null,
            id:null,
            accessibility:{},
            team:null,
            status:null,
            genba:null,
            koushu:null,
        },
        SocketLoadOptions: {
			display: false,
            title:"",
            value:0
		},
        screen:{
            w : 0,
            h : 0 
        },
        contextMenuOptions:{
            display:false
        }
    },
    mutations: {
        activatePrivateLogin(state) {
			state.privateLogin = true
			router.replace("/")
		},
        setSocketLoad(state, options) {
            if(options.display || options.display === false)
			    state.SocketLoadOptions.display = options.display 
            if(options.title)
                state.SocketLoadOptions.title = options.title
            state.SocketLoadOptions.value = options.value

		},
        setContextMenu(state, payload){
            state.contextMenuOptions.display = payload.display
        },
        setScreenSize(state, payload){
            state.screen.w = payload.w
            state.screen.h = payload.h
        },
        setSnack(state, options) {
            state.snackOptions.display = options.display
            if(options.iconColor) state.snackOptions.iconColor = options.iconColor
            if(options.icon) state.snackOptions.icon = options.icon
            if(options.text) state.snackOptions.text = options.text
        },
        setLoadingScreen(state, options) {
            state.loadingScreenOptions.display = options.display
        },
        setUserInfo(state, payload){
            for(let i in payload){
                state.userProfile[i] = payload[i]
            }
        },
        setUserProfile(state, payload){
            if(payload.name) state.userProfile.name = payload.name
            state.userProfile.jwt = payload.j
            if(payload.id) state.userProfile.id = payload.id
        },
        setAccessMap(state, payload){
            state.accessMap = payload
        },
        setAccessibility(state, payload){
            state.userProfile.accessibility = payload
        },
        clearUserData(state){
            state.userProfile = {}
            localStorage.removeItem('lrt')
            localStorage.removeItem('token')
        }
    },
    actions: {
        async get({state, commit, dispatch}, payload){
            try{
                if(payload.showUI) commit('setLoadingScreen',{display:true})
                const headers = {
                    'Content-Type': 'application/json',
                    't':state.userProfile.jwt
                }
                const result = await axios.get(payload.url,{headers})
                if(!result.data) return
                if(payload.showUI){
                    commit('setSnack',{
                        display:true,
                        iconColor:"success",
                        icon:"mdi-alert-circle",
                        text: result.data.msg ? result.data.msg :"完了しました"
                    })
                    commit('setLoadingScreen',{display:false})
                }
                return result.data
            }catch(err){
                if(state.inDevelopment) console.log(err)
                //Check if its jwt time's up
                if(err.response.data.expired){
                    retryCount --
                    if(retryCount === 0) location.reload()
                    await dispatch('refreshToken')
                    return await dispatch('get', payload)
                }else
                if(payload.showUI){
                    commit('setSnack',{
                        display:true,
                        iconColor:"red",
                        icon:"mdi-alert-circle",
                        text: err.response? err.response.data  :"エラーが発生しました。" 
                    })
                    commit('setLoadingScreen',{display:false})
                }
            }   
        },
        async post({state, commit, dispatch}, payload){
            try{
                if(payload.showUI) commit('setLoadingScreen',{display:true})
                const headers = {
                    'Content-Type': 'application/json',
                    't':state.userProfile.jwt
                }
                const result = await axios.post(payload.url, payload.rawData ,{headers})
                if(payload.showUI){
                    commit('setSnack',{
                        display:true,
                        iconColor:"success",
                        icon:"mdi-alert-circle",
                        text: result.data.msg ? result.data.msg :"完了しました"
                    })
                    commit('setLoadingScreen',{display:false})
                }
                return result.data
            }catch(err){
                if(state.inDevelopment) console.log(err)
                //Check if its jwt time's up
                if(err.response.data.expired){
                    retryCount --
                    if(retryCount === 0) location.reload()
                    await dispatch('refreshToken')
                    return await dispatch('post', payload)
                }else
                if(payload.showUI){
                    commit('setSnack',{
                        display:true,
                        iconColor:"red",
                        icon:"mdi-alert-circle",
                        text: err.response? err.response.data  :"エラーが発生しました。" 
                    })
                    commit('setLoadingScreen',{display:false})
                }
            }   
        },
        async getAccessMap({state, commit, dispatch}){
            const result = await dispatch('get', {url:'/api/getAccessMap'})
            if(result)
                commit('setAccessMap', result.accessMap)
                commit('setAccessibility', result.accessibility.accessible)
        },
        async refreshToken({state, commit}){
            try{
                let response = await axios.post("/auth/refresh",
                    {
                        j:state.userProfile.jwt
                    },
                    {
                        withCredentials:true,
                    }
                )
                return commit("setUserProfile",response.data)
            }catch(err){
                if(state.inDevelopment) console.log(err)
                return
            }  
        },
        checkAccessibility({state}, payload){
            for(let sectionId in state.accessMap){
                if(state.accessMap[sectionId] === payload){
                    if(state.userProfile.accessibility[sectionId] === true)
                        return true
                    else return false
                }
            }
            return false
        },
        async checkIfHasAdmin({state}){
            try{
                const result = (await axios.get('/api/checkIfHasAdmin')).data
                if(result === true) return true
                else return false 
            }catch(err){
                if(state.inDevelopment)
                    console.log(err)
                if(err.message === "Network Error")
                    return err.message
            }
        },
        login ({ state }, payload) {
            return axios.post("/auth/login", payload,{
                withCredentials:true
            })
        },
        loginClient ({ state }, payload) {
            return axios.post("/auth/loginClient", payload,{
                withCredentials:true
            })
        },
        async logout ({ state, commit }, payload) {
            try{
                await axios.get("/auth/logout",{
                    withCredentials:true
                })
                commit("clearUserData")
                router.replace("/")
            }catch(err){
                if(state.inDevelopment) console.log(err)
            }   
        },
        async autoLogin({state, commit}){
            try{
                commit('setLoadingScreen',{display:true})
                let newJwt = await axios.get("/auth/autoLog",{
                    withCredentials:true
                })
                await commit("setUserProfile",newJwt.data)
        
                if(newJwt.data.acc === 38560923)
                    router.replace("/dash")
                else if(newJwt.data.acc === 1)
                    router.replace("/cDash")
                else
                    router.replace("/")
                commit('setLoadingScreen',{display:false})

            }catch(err){
                if(state.inDevelopment) console.log(err)
                commit('setLoadingScreen',{display:false})
            }   
        },
        async verifyUser({state}, accType){
            try{
                let response = await axios.get("/auth/verify",{
                    withCredentials:true,
                    headers:{
                        'Content-Type': 'application/json',
                        'j':state.userProfile.jwt,
                        't':accType,
                    }
                })
                return response.data
            }catch(err){
                if(state.inDevelopment) console.log(err)
            }  
        }
    },
    modules: {
    }
})
