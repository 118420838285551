<template>
    <v-app class="cusFont">
        <v-main>
            <router-view />
        </v-main>
        <!--SnackBar-->
        <v-snackbar v-model="$store.state.snackOptions.display">
            <div class="d-flex justify-center">
                <v-icon :color="$store.state.snackOptions.iconColor">{{
                    $store.state.snackOptions.icon
                }}</v-icon>
                <div class="d-flex align-center">
                    {{ $store.state.snackOptions.text }}
                </div>
                <v-btn
                    color="pink"
                    text dense
                    @click="closeSnack"
                >
                    <v-icon>mdi-close</v-icon>
                </v-btn>
            </div>
        </v-snackbar>
        <!--LoadingScreen-->
        <v-overlay
            :value="$store.state.loadingScreenOptions.display"
            style="z-index: 999999"
        >
            <v-progress-circular indeterminate size="100"></v-progress-circular>
        </v-overlay>
        <!--Confirmation-->
        <confirmation ref="confirm" />
        <socket-progress-bar />
        <!--Inactive-->
        <v-dialog v-model="inactiveDisplay" persistent width="500px">
            <inactive-panel  @close="inactiveDisplay = false" />
        </v-dialog>
        <!--PWA UPDATE-->
        <v-snackbar bottom right :value="updateExists" :timeout="-1" color="primary">
            <div class="d-flex align-center">
                新しバージョンがあります
                <v-spacer />
                <v-btn @click="refreshApp" color=success>
                    更新
                </v-btn>
            </div>
        </v-snackbar>
    </v-app>
</template>

<script>
import Confirmation from "./components/generalComponents/confirmationPanel.vue";
import SocketProgressBar from "./components/generalComponents/SocketProgressBar.vue"
import InactivePanel from "./components/generalComponents/InactivePanel.vue"
export default {
    data:()=>({
        inactiveDisplay:false,
        //PWA related
        refreshing:false,
        registration: null,
        updateExists: false,
    }),
    components:{
        Confirmation,
        SocketProgressBar,
        InactivePanel
    },
    methods: {
        closeSnack(){
            this.$store.commit('setSnack', {display:false})
        },
        loadMode(){
            if(Boolean(localStorage.getItem('d')))
                this.$vuetify.theme.dark = Boolean(localStorage.getItem('d'))
        },
        idleLogout() {
            let t;
            let v = this;
            window.onload = resetTimer;
            window.onmousemove = resetTimer;
            window.onmousedown = resetTimer;  // catches touchscreen presses as well      
            window.ontouchstart = resetTimer; // catches touchscreen swipes as well 
            window.onclick = resetTimer;      // catches touchpad clicks as well
            window.onkeydown = resetTimer;   
            window.addEventListener('scroll', resetTimer, true); // improved; see comments
            function displayInactiveDialog() {
                console.log("Inactivity Detected")
                v.inactiveDisplay = true
                // your function for too long inactivity goes here
                // e.g. window.location.href = 'logout.php';
            }
            function resetTimer() {
                clearTimeout(t);
                t = setTimeout(displayInactiveDialog, 1000 * 60 * 15);  // time is in milliseconds
            }
        },
        //PWA
        updateAvailable(e){
            this.registration = e.detail
            this.updateExists = true
        },
        refreshApp() {
            this.updateExists = false
            // Make sure we only send a 'skip waiting' message if the SW is waiting
            if (!this.registration || !this.registration.waiting) return
            // Send message to SW to skip the waiting and activate the new SW
            this.registration.waiting.postMessage({ type: 'SKIP_WAITING' })
        }
    },
    created() {
        document.addEventListener('swUpdated', this.updateAvailable, {once : true})
        navigator.serviceWorker.addEventListener('controllerchange', () => {
            // We'll also need to add 'refreshing' to our data originally set to false.
            if (this.refreshing) return
            this.refreshing = true
            // Here the actual reload of the page occurs
            window.location.reload()
        })
    },
    mounted() {
        this.idleLogout();
        this.$store.dispatch("autoLogin");
        this.loadMode()
        this.$root.$confirm = this.$refs.confirm;
        if (this.$store.state.isProduction) {
            setInterval(() => {
                console.clear();
            }, 1000);
        }
        //Resize
        let tmpVue = this.$store
        window.addEventListener("resize", function() {
            tmpVue.commit('setScreenSize', {w:window.innerWidth, h:window.innerHeight})
        })
        tmpVue.commit('setScreenSize', {w:window.innerWidth, h:window.innerHeight})
    },
};
</script>
<style>
@import "../node_modules/animate.css/animate.css";
.cusFont * {
    font-family: "Noto Sans JP", sans-serif;
}
body{
    font-size:110%;
}
.btnPanel{
    display: grid;
    gap: 1em;
    grid-auto-flow: column;
    justify-content: start;
}
</style>