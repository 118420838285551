const store = require('../store/index').default

module.exports = {
    async getTeamList(listOnly){
        let response = await store.dispatch('get', {url:"/api/teamList", showUI:true})
        if(response){
            if(listOnly){
                response = response.map(team => team = team.teamName)
                return response
            }
            return response
        }
            
    },
    async getUserRate(type){//type 1 = individual , 2 = grouped
        if(!type) return
        let response = null
        if(type === 1){
            response = await store.dispatch('get', {url:"/api/getIndividualRateInfo", showUI:true})
        }else if(type === 2){
            response = await store.dispatch('get', {url:"/api/getRateGroup", showUI:true})
        }
        if(response.result)
            return response.result
    } ,
    async getKoushu(){
        let response = await store.dispatch('get', {url:"/api/getKoushu", showUI:true})
        if(response.result)
            return response.result
    },
    async getGenba(){
        let response = await store.dispatch('get', {url:"/api/getGenba", showUI:true})
        if(response.result)
            return response.result
    },
    async getGenbaKoushu(){
        let response = await store.dispatch('get', {url:"/api/getGenbaKoushu", showUI:true})
        if(response.result)
            return response.result
    },
    async getSettings(){
        let response = await store.dispatch('get', {url:"/api/getSettings", showUI:true})
        if(response.result)
            return response.result
    },
    async getCalendarRules() {
        const response = await store.dispatch("get", {
            url: "/api/getCalendarRules",
            showUI: true,
        });
        if (response.result) {
            return response.result;
        }
    },
}