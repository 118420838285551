<template>
    <div v-if="$store.state.SocketLoadOptions.display" id="SocketProgressbar" transition="slide-x-transition">
        <v-sheet class="primary white--text">
            <span class="caption ml-3 justify-center">
                {{$store.state.SocketLoadOptions.title}}
            </span>
            <v-icon id="action-minimize" @click="minimize">mdi-minus-box-outline</v-icon>
        </v-sheet>
        <v-card id="cardPanel">
            <v-card-text>
                <v-progress-linear
                    color="primary"
                    buffer-value="0"
                    stream
                ></v-progress-linear>
                <v-progress-linear id="progressLinear" :value="$store.state.SocketLoadOptions.value" height="25">
                    <strong>{{ Math.ceil($store.state.SocketLoadOptions.value) }}%</strong>
                </v-progress-linear>
            </v-card-text>
        </v-card>
    </div>
</template>

<script>

export default({
    data:()=>({
        minStatus:false
    }),
    methods:{
        minimize(){
            const loadingPanel = document.getElementById('cardPanel')
            const progressBar = document.getElementById('progressLinear')
            if(this.minStatus){
                progressBar.style.height = "25px"
                loadingPanel.style.height = "55px"
            }else{
                progressBar.style.height = "0px"
                loadingPanel.style.height = "0px"
                
            }
            this.minStatus = !this.minStatus  
        }
    }
})
</script>


<style scoped>
#SocketProgressbar{
    position: fixed;
    bottom: 2em;
    right:2em;
}
#cardPanel{
    transition: .25s all ease-in-out;
    height:55px;
    min-width:200px;
}
#action-minimize{
    position:absolute;
    right: 0;   
}
</style>