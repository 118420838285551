import Vue from 'vue'
import VueRouter from 'vue-router'
import store from '../store/index'

Vue.use(VueRouter)

const routes = [
    {
        path: '/',
        async beforeEnter(to, from, next) {
            const result = await store.dispatch('checkIfHasAdmin')
            if(result === "Network Error") next("noInternet")
            if (result) next()
            else next('/initAdmin')
        },
        component: () => import('../views/Login.vue')
    },
    {
        path: '/cDash',
        beforeEnter: async (to, from, next) => {
            try {
                if (!store.state.userProfile.jwt) next('/')
                let response = await store.dispatch('verifyUser', 1)
                if (response)  next()
                else next('/')
            } catch (err) {
                if (!store.state.isProduction) console.log(err)
                next('/')
            }
        },
        component: () => import('../views/clientDashPanel/clientDash.vue')
    },
    {
        path: '/al',
        async beforeEnter(to, from, next) {
            const result = await store.dispatch('checkIfHasAdmin')
            if (result) next()
            else next('/initAdmin')
        },
        component: () => import('../components/AdminLoginForm.vue')
    },
    {
        path: '/dash/',
        beforeEnter: async (to, from, next) => {
            try {
                if (!store.state.userProfile.jwt) next('/')
                let response = await store.dispatch('verifyUser', 38560923)
                if (response) {
                    await store.dispatch('getAccessMap')
                    next()
                }
                else next('/')
            } catch (err) {
                if (!store.state.isProduction) console.log(err)
                next('/')
            }
        },
        component: () => import("../views/dashboard.vue"),
        children: [
            {
                path: '/blank',
                name: "NO ACCESS",
            },
            {
                path: '',
                name: "ホーム",
                beforeEnter: async (to, from, next) => {
                    const check = await store.dispatch('checkAccessibility', to.name)
                    if (check) next()
                    else next({ name: 'NO ACCESS' })
                },
                component: () => import("../views/HomePanel/main.vue")
            },
            {
                path: 'userlist',
                name: "ユーザー管理",
                beforeEnter: async (to, from, next) => {
                    const check = await store.dispatch('checkAccessibility', to.name)
                    if (check) next()
                    else next({ name: 'NO ACCESS' })
                },
                component: () => import("../views/userPanel/userListPanel.vue")
            },
            {
                path: 'userRoles',
                name: "権限管理",
                beforeEnter: async (to, from, next) => {
                    const check = await store.dispatch('checkAccessibility', to.name)
                    if (check) next()
                    else next({ name: 'NO ACCESS' })
                },
                component: () => import("../views/userRolesPanel.vue")
            },
            {
                path: 'shift',
                name: "シフト管理",
                beforeEnter: async (to, from, next) => {
                    const check = await store.dispatch('checkAccessibility', to.name)
                    if (check) next()
                    else next({ name: 'NO ACCESS' })
                },
                component: () => import("../views/shiftPanel/shiftPanel.vue")
            },
            {
                path: 'paidLeave',
                name: "有給管理",
                beforeEnter: async (to, from, next) => {
                    const check = await store.dispatch('checkAccessibility', to.name)
                    if (check) next()
                    else next({ name: 'NO ACCESS' })
                },
                component: () => import("../views/paidLeavePanel/paidLeavePanel.vue")
            },
            {
                path: 'team',
                name: "班管理",
                beforeEnter: async (to, from, next) => {
                    const check = await store.dispatch('checkAccessibility', to.name)
                    if (check) next()
                    else next({ name: 'NO ACCESS' })
                },
                component: () => import("../views/teamPanel/TeamControlPanel.vue")
            },
            {
                path: 'genbaKoushu',
                name: "現場・工種管理",
                beforeEnter: async (to, from, next) => {
                    const check = await store.dispatch('checkAccessibility', to.name)
                    if (check) next()
                    else next({ name: 'NO ACCESS' })
                },
                component: () => import("../views/GenbaKoushuPanel/GenbaKoushuPanel.vue")
            },
            {
                path: 'cqrcode',
                name: "QRコード作成",
                beforeEnter: async (to, from, next) => {
                    const check = await store.dispatch('checkAccessibility', to.name)
                    if (check) next()
                    else next({ name: 'NO ACCESS' })
                },
                component: () => import("../views/CreateQRCodePanel/createQRCode.vue")
            },
            {
                path: 'rateSettings',
                name: "単価設定",
                beforeEnter: async (to, from, next) => {
                    const check = await store.dispatch('checkAccessibility', to.name)
                    if (check) next()
                    else next({ name: 'NO ACCESS' })
                },
                component: () => import("../views/rateSettingPanel/rateSettingPanel.vue")
            },
            {
                path: 'calendar',
                name: "休日・祝日設定",
                beforeEnter: async (to, from, next) => {
                    const check = await store.dispatch('checkAccessibility', to.name)
                    if (check) next()
                    else next({ name: 'NO ACCESS' })
                },
                component: () => import("../views/CalendarPanel/calendarSettings.vue")
            },
            {
                path: 'otherSettings',
                name: "その他の設定",
                beforeEnter: async (to, from, next) => {
                    const check = await store.dispatch('checkAccessibility', to.name)
                    if (check) next()
                    else next({ name: 'NO ACCESS' })
                },
                component: () => import("../views/otherSettingsPanel/otherSettings.vue")
            },
            {
                path: 'shinsei',
                name: "申請一覧",
                beforeEnter: async (to, from, next) => {
                    const check = await store.dispatch('checkAccessibility', to.name)
                    if (check) next()
                    else next({ name: 'NO ACCESS' })
                },
                component: () => import("../views/shinseiPanel/shinseiPanel.vue")
            },
            {
                path: 'toukei',
                name: "統計",
                beforeEnter: async (to, from, next) => {
                    const check = await store.dispatch('checkAccessibility', to.name)
                    if (check) next()
                    else next({ name: 'NO ACCESS' })
                },
                component: () => import("../views/toukeiPanel/toukei.vue")
            },
        ]
    },
    {
        path: '/noInternet',
        component: () => import('../views/networkError.vue')
    },
    {
        path: '/initAdmin',
        async beforeEnter(to, from, next) {
            const result = await store.dispatch('checkIfHasAdmin')
            if (result) next('/')
            else next()
        },
        component: () => import('../views/InitAdmin.vue')
    },
]

const router = new VueRouter({
    routes
})

export default router
