const dayjs = require('dayjs')
const duration = require('dayjs/plugin/duration')
const utc = require('dayjs/plugin/utc')
const timezone = require('dayjs/plugin/timezone')
const isBetween = require("dayjs/plugin/isBetween")
const isSameOrBefore = require('dayjs/plugin/isSameOrBefore')
dayjs.extend(isSameOrBefore)
dayjs.extend(isBetween);
dayjs.extend(duration)
dayjs.extend(utc)
dayjs.extend(timezone)
dayjs.tz.setDefault("Asia/Tokyo")

module.exports = {
    getDaysBetweenDates(startDate, endDate){
        startDate = dayjs(startDate)
        endDate = dayjs(endDate)
        let now = startDate.clone(), dates = [];
        while(now.isSameOrBefore(endDate)){
            dates.push(now.format('YYYY-MM-DD'));
            now = now.add(1, 'd');
        }
        return dates;
    },
    downloadCSV(csv_string, filename){         
        let bom = new Uint8Array([0xef, 0xbb, 0xbf]);
        let blob = new Blob([bom, csv_string], { type: "text/csv" });
        let url = (window.URL || window.webkitURL).createObjectURL(blob);
        let link = document.createElement("a");
        link.style.display = "none";
        link.setAttribute("href", url);
        link.setAttribute("download", filename);
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
    },
    //CalendarRules
    /*
        Return : List of Filtered dates
        Params : 
            dates<Array|required> : List of dates to filter
            start<dayjs()|required> : start date to filter,
            end<dayjs()|required> : End date to filter
    */
    filterDatesBetween(dates, start, end){
        return dates.filter((date,index)=>{
            if (dayjs(date).isBetween(start, end, null, "[]")) 
                return dates[index]
        })    
    },
    //All of the generator return Arrays
    /*
        Return : List of parsed events
        Params : 
            rules<Array|required> : List of  event rules
            dateOnly<Boolean|options> : return date only array
    */
    parseEventRules(rules, dateOnly = false){
        let events = []
        rules.forEach((rule) => {
            switch (rule.type) {
                case 1:
                    events = events.concat(
                        dateOnly ? rule.value.start :
                        module.exports.generateType1Events(rule)
                    );
                    break;
                case 2:
                    events = events.concat(
                        module.exports.generateType2Events(rule, dateOnly)
                    );
                    break;
                case 3:
                    events = events.concat(
                        dateOnly ? module.exports.getDaysBetweenDates(dayjs(rule.value.start),dayjs(rule.value.end)) :
                        module.exports.generateType3Events(rule)
                    );
                    break;
            }
        });
        events.sort()
        if(dateOnly) events = Array.from(new Set(events))
        return events
    },
    generateType1Events(rule) {　//Currently It's the same as type 3
        let tmpEvent = {
            name: rule.name,
            color: rule.color,
            start: rule.value.start,
            end: rule.value.end,
        };
        return [tmpEvent];
    },
    generateType2Events(rule, dateOnly) {
        let start = dayjs(String(rule.value.year)).month(0);
        let end = dayjs(String(rule.value.year))
            .add(1, "y")
            .month(-1)
            .add(1, "M")
            .date(-1)
            .add(1, "d");
        let result = [];
        let startPoint = start;
        while (startPoint.isBetween(start, end, null, "[]")) {
            let tmpTargetDay = dayjs(startPoint).day(rule.value.every);
            if (tmpTargetDay.isBetween(start, end, null, "[]")) {
                if (dateOnly)  
                    result.push(tmpTargetDay.format("YYYY-MM-DD"))
                else
                    result.push({
                        name: rule.name,
                        color: rule.color,
                        start: tmpTargetDay.format("YYYY-MM-DD"),
                        end: tmpTargetDay.format("YYYY-MM-DD"),
                    })
            }
            startPoint = startPoint.add(7, "d");
        }
        return result;
    },
    generateType3Events(rule) { //Currently It's the same as type 1
        let tmpEvent = {
            name: rule.name,
            color: rule.color,
            start: rule.value.start,
            end: rule.value.end,
        };
        return [tmpEvent];
    },
    convertYen(value, digitOnly){
        return digitOnly? 
        (new Intl.NumberFormat('ja-JP', { style: 'decimal'}).format(value).split(".")[0]) :
        new Intl.NumberFormat('ja-JP', { style: 'currency', currency: 'JPY' }).format(value)
    },
    convertRateType(type){
        let RateTypeMap = {
            "1":"時給",
            "2":"日給",
            "3":"月給"
        }
        return RateTypeMap[type]
    },
    convertToDay(day, short=false){
        if(day === 7) day = 0
        let dayMap = {
            "1":"月曜日",
            "2":"火曜日",
            "3":"水曜日",
            "4":"木曜日",
            "5":"金曜日",
            "6":"土曜日",
            "0":"日曜日",
        }
        if(short){
            dayMap = {
                "1":"月",
                "2":"火",
                "3":"水",
                "4":"木",
                "5":"金",
                "6":"土",
                "0":"日",
            }
        }
        return dayMap[day]
    },
    convertTime(date){
        return dayjs.duration(date).asHours().toFixed(2)
    },
    convertLabel(recordType){
        const isEqual = (left, right)=>{
            if (left.length !== right.length) return false;
            for (var i = 0; i < left.length; i++) {
                if (left[i] !== right[i]) return false;
            }
            return true;
        }
        if(isEqual(recordType,[true,false,false])){
            return "出勤"
        }
        else if(isEqual(recordType,[true,true,false])){
            return "休憩"
        }
        else if(isEqual(recordType,[true,false,true])){
            return "移動"
        }
    }
}