import Vue from 'vue'
import App from './App.vue'
import './registerServiceWorker'
import router from './router'
import store from './store'
import vuetify from './plugins/vuetify';
import axios from 'axios'
import globalFunction from './globalFunctions'
import getHandlers from './handlers/getHandlers'
import postHandlers from './handlers/postHandlers'

//const serverUrl = "http://localhost:4897"
const serverUrl = "https://kintai.arknet.online"

axios.defaults.baseURL = serverUrl
Vue.prototype.$hostUrl = serverUrl
Vue.config.productionTip = false

Vue.prototype.$gf = globalFunction
Vue.prototype.$get = getHandlers
Vue.prototype.$post = postHandlers

new Vue({
  router,
  store,
  vuetify,
  render: h => h(App)
}).$mount('#app')
